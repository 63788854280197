.fields {
  display: grid;
  grid-template-columns: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
}

.inputs {
  display: grid;
  grid-template-columns: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
}

.grow,
.switches {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media screen and (min-width: 480px) {
  .fields {
    grid-template-columns: auto auto;
  }
  .switches {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media screen and (min-width: 768px) {
  .fields {
    grid-template-columns: auto auto auto;
  }
  .inputs {
    grid-template-columns: auto auto;
  }
  .switches {
    grid-column-start: 3;
    grid-column-end: 3;
  }
}
