.content {
  margin-bottom: 0.5rem;
}

.permissions {
  display: inline-flex;
  align-items: center;
  padding: 1px;
}

.show,
.hide {
  display: inline-flex;
  align-items: center;
  color: var(--color-brand);
  &:hover {
    color: inherit;
  }
}

.hide {
  & svg {
    rotate: 180deg;
  }
}

.normal {
  transition: all 0.3s ease-in;
  max-height: 200px;
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
  & > button {
    margin: var(--padding) 0;
  }
}

.hidden {
  max-height: 0px;
  opacity: 0;
  visibility: hidden;
}
