.topbar {
  font-size: var(--font-size);
  color: var(--color-primary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 0px;
  white-space: nowrap;
  align-items: center;
  button {
    margin-left: 1em;
    @media screen and (max-width: 768px) {
      & {
        margin-left: 8px;
      }
    }
  }
}
