$border-color: #ffe4e1;
$background-color: #333;
$border-radius: 8px;
$font-size: 24px;

$car-color: $border-color;
$spin-deg: 36deg;
$width: 300px;
$height: 200px;

@keyframes spin {
  0% {
    transform: translate(2px, 1px) rotate($spin-deg * 0);
  }
  10% {
    transform: translate(-1px, -3px) rotate($spin-deg * 1);
  }
  20% {
    transform: translate(-2px, 0px) rotate($spin-deg * 2);
  }
  30% {
    transform: translate(1px, 2px) rotate($spin-deg * 3);
  }
  40% {
    transform: translate(1px, -1px) rotate($spin-deg * 4);
  }
  50% {
    transform: translate(-1px, 3px) rotate($spin-deg * 5);
  }
  60% {
    transform: translate(-1px, 1px) rotate($spin-deg * 6);
  }
  70% {
    transform: translate(3px, 1px) rotate($spin-deg * 7);
  }
  80% {
    transform: translate(-2px, -1px) rotate($spin-deg * 8);
  }
  90% {
    transform: translate(2px, 1px) rotate($spin-deg * 9);
  }
  100% {
    transform: translate(1px, -2px) rotate($spin-deg * 10);
  }
}
@keyframes speed {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes strikes {
  from {
    left: 25px;
  }
  to {
    left: -80px;
    opacity: 0;
  }
}
@keyframes dots {
  from {
    width: 0px;
  }
  to {
    width: 20px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

%strike {
  position: absolute;
  width: 11px;
  height: 1px;
  background: $car-color;
  animation: strikes 0.2s linear infinite;
}
%detail {
  position: absolute;
  display: block;
  background: $car-color;
  animation: speed 0.5s linear infinite;
}
%wheel {
  @extend %detail;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 20px;
  left: 12px;
  border: 3px solid $background-color;
  background: linear-gradient(45deg, transparent 45%, $border-color 46%, $border-color 54%, transparent 55%),
    linear-gradient(-45deg, transparent 45%, $border-color 46%, $border-color 54%, transparent 55%),
    linear-gradient(90deg, transparent 45%, $border-color 46%, $border-color 54%, transparent 55%),
    linear-gradient(0deg, transparent 45%, $border-color 46%, $border-color 54%, transparent 55%),
    radial-gradient($border-color 29%, transparent 30%, transparent 50%, $border-color 51%), $background-color;
  animation-name: spin;
}

.screen {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111d;
  display: flex;
  align-items: center;
}
.flat {
  display: block;
  padding: $border-radius;
}
.box {
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  height: $height;
  width: $width;
  color: $car-color;
  background: $background-color;
  border: 3px solid $border-color;
  border-radius: $border-radius;
  .text {
    font-size: $font-size;
    position: absolute;
    width: auto;
    top: 70%;
    left: 35%;
    margin: 0 auto;
  }
  .dots {
    display: inline-block;
    width: 20px;
    overflow: hidden;
    vertical-align: bottom;
    animation: dots 1.5s linear infinite;
    transition: 1;
  }
}
.car {
  position: absolute;
  width: 117px;
  height: 42px;
  left: 92px;
  top: 70px;
}
.strike {
  @extend %strike;
}
.strike2 {
  @extend %strike;
  top: 11px;
  animation-delay: 0.05s;
}
.strike3 {
  @extend %strike;
  top: 22px;
  animation-delay: 0.1s;
}
.strike4 {
  @extend %strike;
  top: 33px;
  animation-delay: 0.15s;
}
.strike5 {
  @extend %strike;
  top: 44px;
  animation-delay: 0.2s;
}
.spoiler {
  @extend %detail;
  top: 7px;
  background: none;
  border: 20px solid transparent;
  border-bottom: 8px solid $car-color;
  border-left: 20px solid $car-color;
}
.back {
  @extend %detail;
  height: 20px;
  width: 92px;
  top: 15px;
  left: 0px;
}
.center {
  @extend %detail;
  height: 35px;
  width: 75px;
  left: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 45px 40px;
  border: 4px solid $car-color;
  background: none;
  box-sizing: border-box;
}
.center1 {
  @extend %detail;
  height: 35px;
  width: 35px;
  left: 12px;
  border-top-left-radius: 30px;
}
.front {
  @extend %detail;
  height: 20px;
  width: 50px;
  top: 15px;
  left: 67px;
  border-top-right-radius: 50px 40px;
  border-bottom-right-radius: 10px;
}
.wheel {
  @extend %wheel;
  left: 12px;
}
.wheel2 {
  @extend %wheel;
  left: 82px;
}
