.stats {
  & > h1 {
    margin: calc(var(--padding) * 2) var(--padding) var(--padding);
  }
}
.table {
  border: 1px solid transparent;
  // border: 1px solid var(--color-border);
  border-radius: var(--padding);
  background-color: var(--color-dialog);
  thead tr {
    border-bottom: 1px solid var(--color-border) !important;
    // background-color: var(--color-input);
  }
  tr {
    border-bottom: 1px solid var(--color-border);
    &:last-of-type {
      border-bottom: 0;
    }
  }
  th,
  td {
    font-weight: 500;
    text-align: center;
    padding: 8px 0px;
    @media screen and (max-width: 480px) {
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      &:nth-child(6) {
        display: none;
      }
      &:nth-child(7) {
        display: none;
      }
      &:nth-child(9) {
        display: none;
      }
    }
  }
  th {
    text-transform: uppercase;
  }
  td {
    color: var(--color-secondary);
    width: 10%;
  }
  & .result td {
    color: inherit;
  }
}
