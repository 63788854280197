.btn {
  min-width: 120px;
  padding: 8px 10px;
  font-weight: 500;
  color: $texth;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0.4rem;
    font-size: 1.5rem;
    padding: 0.1rem;
    color: inherit;
  }
  &-small {
    min-width: unset;
    padding: 0 $indent / 2;
    font-weight: 200;
  }
  &-icon {
    min-width: $indent * 6;
    margin-right: $indent;
    font-weight: 200;
    &:last-child {
      margin-right: 0;
    }
  }
  &.green {
    background-color: darken($success, 25%); // #4a8b3e;
    // background-image: linear-gradient(to top, rgba($green, 0.25) 100%, rgba($green, 0.65));
    &:hover {
      background-color: darken($success, 20%); // #4a8b3e;
      // background-image: linear-gradient(to top, rgba($green, 0.4) 100%, rgba($green, 0.65));
    }
  }
  &.red {
    background-color: lighten($error, 7%);
    // background-image: linear-gradient(to top, rgba($red, 0.25) 100%, rgba($red, 0.65));
    &:hover {
      background-color: lighten($error, 2%);
      // background-image: linear-gradient(to top, rgba($red, 0.4) 100%, rgba($red, 0.65));
    }
  }
  &.orange {
    background-color: darken($warning, 8%);
    &:hover {
      background-color: darken($warning, 3%);
    }
  }
  &.blue {
    background-color: lighten($info, 13%);
    &:hover {
      background-color: lighten($info, 8%);
    }
  }
  &.gray:hover {
    background: rgba($shadow, 0.6);
  }
  &:active {
    transform: translate(1px, 2px);
  }
  @media screen and (max-width: 768px) {
    & {
      min-width: 105px;
      padding: 4px 10px;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.row,
.column {
  &.center {
    align-items: center;
  }
  // &.stretch {
  //   align-items: stretch;
  // }
  &.justify {
    justify-content: space-between;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.middle {
    justify-content: center;
  }
  &.max-h {
    min-height: 90vh;
  }
}

.w-100 {
  width: 100%;
}

// CREATE MIXIN and SO ON
.col {
  width: 100%;
  position: relative;
  &-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  &-2 {
    flex: 0 0 16.66666%;
    max-width: 16.66666%;
  }
  &-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  &-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  &-5 {
    flex: 0 0 41.66666%;
    max-width: 41.66666%;
  }
  &-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  &-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  &-8 {
    flex: 0 0 66.66666%;
    max-width: 66.66666%;
  }
  &-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  &-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  &.offset {
    &-1 {
      margin-left: 8.33333%;
    }
    &-2 {
      margin-left: 16.66666%;
    }
    &-3 {
      margin-left: 25%;
    }
  }
}
// h - horisontal
.m {
  &-0 {
    margin: $indent / 2 0;
  }
  &-1 {
    margin: $indent 0;
  }
  &-2 {
    margin: $indent * 2 0;
  }
  &-3 {
    margin: $indent * 3 0;
  }
  &b-0 {
    margin-bottom: $indent/2;
  }
  &b-1 {
    margin-bottom: $indent;
  }
  &b-2 {
    margin-bottom: $indent * 2;
  }
  &b-3 {
    margin-bottom: $indent * 3;
  }
  &h {
    &-1 {
      margin-left: $indent;
      margin-right: $indent;
    }
    &-2 {
      margin-left: $indent * 2;
      margin-right: $indent * 2;
    }
    &-3 {
      margin-left: $indent * 3;
      margin-right: $indent * 3;
    }
  }
  &t {
    &-0 {
      margin-top: $indent/2;
    }
    &-1 {
      margin-top: $indent;
    }
    &-2 {
      margin-top: $indent * 2;
    }
    &-3 {
      margin-top: $indent * 3;
    }
  }
  &r {
    &-0 {
      margin-right: $indent/2;
    }
    &-1 {
      margin-right: $indent;
    }
    &-2 {
      margin-right: $indent * 2;
    }
    &-3 {
      margin-right: $indent * 3;
    }
  }
  &l {
    &-0 {
      margin-left: $indent/2;
    }
    &-1 {
      margin-left: $indent;
    }
    &-2 {
      margin-left: $indent * 2;
    }
    &-3 {
      margin-left: $indent * 3;
    }
  }
}
.p {
  &-0 {
    padding-top: $indent / 2;
    padding-bottom: $indent / 2;
  }
  &-1 {
    padding-top: $indent;
    padding-bottom: $indent;
  }
  &-2 {
    padding-top: $indent * 2;
    padding-bottom: $indent * 2;
  }
  &h {
    &-1 {
      padding-left: $indent;
      padding-right: $indent;
    }
    &-2 {
      padding-left: $indent * 2;
      padding-right: $indent * 2;
    }
    &-3 {
      padding-left: $indent * 3;
      padding-right: $indent * 3;
    }
  }
  &t {
    &-0 {
      padding-top: $indent/2;
    }
  }
  &l {
    &-1 {
      padding-left: $indent;
    }
  }
  &r {
    &-0 {
      padding-right: $indent/2;
    }
    &-1 {
      padding-right: $indent;
    }
    &-2 {
      padding-right: $indent * 2;
    }
    &-3 {
      padding-right: $indent * 3;
    }
  }
}

.progress-bar {
  overflow: hidden;
  height: 20px;
  background-color: rgba($texth, 0.9);
  border-radius: $indent / 1.5;
  box-shadow: inset 0 1px 2px $shadow;
  & > div {
    box-sizing: content-box;
    height: 100%;
    background-color: #6753d8; //not main
    border-top-left-radius: $indent / 1.5;
    border-bottom-left-radius: $indent / 1.5;
    background-size: 40px 40px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width 0.6s ease;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    animation: progress-bar 2s linear infinite;
    @keyframes progress-bar {
      0% {
        background-position: 40px 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }
  &.finish {
    & > div {
      border-top-right-radius: $indent / 1.5;
      border-bottom-right-radius: $indent / 1.5;
    }
  }
}

.shadow-box {
  background-color: var(--color-dialog);
  border: 1px solid var(--color-border);
  border-radius: calc(var(--padding, 8px) * 2);
  padding: calc(var(--padding, 8px) * 2);
  @extend %shadow;
}

.disabled {
  @extend %disabled;
}
