.bot {
  background-color: var(--color-dialog);
  padding: 16px 32px;
  white-space: nowrap;
  border: 1px solid var(--color-border);
  border-radius: var(--radius);
  margin: var(--padding) 0;
  &:hover {
    background-color: var(--color-active);
  }
  h1 {
    font-size: 20px;
  }
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
  }
  .table {
    padding: 16px 0;
  }
  @media screen and (max-width: 576px) {
    & {
      padding: var(--padding);
    }
  }
}
