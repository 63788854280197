.title {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 40px;
}
.total {
  flex-flow: row;
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
  & > div {
    flex-grow: 1;
    width: 33%;
    box-sizing: border-box;
    border-right: 1px solid var(--color-border);
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-of-type {
      border-right: 0;
    }
    & > h1 {
      color: var(--color-brand);
    }
  }
  @media screen and (max-width: 968px) {
    & {
      flex-flow: wrap;
    }
    & > div:nth-child(3) {
      border-right: none;
    }
  }
}
