.ok,
.err,
.load {
  display: inline-flex;
  align-items: center;
  margin-left: 0.4rem;
}

.ok {
  color: var(--color-msg-success);
}
.err {
  color: var(--color-msg-error);
}
