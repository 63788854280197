.soup {
  // --color-1: #0036a4;
  // --color-2: #6a2364;
  // --color-3: #4cceff;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  // object-fit: cover;
  // background-color: #06021d;
  z-index: -1;
}
