::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #41464f;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  background-color: #1d2033;
  box-shadow: inset 0 0 4px #372f47;
}

// @media screen and (min-width: 576px) {
//   ::-webkit-scrollbar {
//     width: 6px;
//   }
// }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: Kanit, notosans, sans-serif;
  font-size: var(--font-size);
  font-weight: 400;
  color: var(--color-primary);
  overflow-y: scroll;
  background: linear-gradient(to right, #131619, #040506);
}
hr {
  padding-top: 1px;
  margin: var(--base-size-8, 8px) 0;
  background-color: var(--color-border);
  border: none;
}
h1,
h2,
h3,
h4 {
  font-family: montserrat, sans-serif;
  letter-spacing: 1.5px;
  font-weight: 700;
}
h1 {
  font-size: var(--font-size-h1, 24px);
}
h2 {
  font-size: var(--font-size-h2, 20px);
}
h3 {
  text-transform: uppercase;
  font-weight: 500;
}
button,
input,
textarea {
  font: inherit;
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
input[type='text'],
input[type='password'],
input[type='email'],
select {
  width: 100%;
  font-size: inherit;
}
input[type='checkbox'] {
  width: var(--size-checkbox, 24px);
  height: $indent * 3;
  cursor: pointer;
  position: relative;
  &:checked::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(var(--color-msg-error), 0.35);
  }
}
input {
  display: block;
  border: 1px solid var(--color-border);
  border-radius: var(--radius, 8px);
  padding: 8px 12px;
  color: var(--color-primary);
  background: var(--color-input);
  background: transparent;
  &:focus {
    background: var(--color-active);
    border-color: var(--color-disabled);
  }
}

a,
button {
  color: inherit;
  background: transparent;
  letter-spacing: 0.9px;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  &.underline {
    font-weight: 500;
    color: var(--color-secondary);
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: transparent;
      border-radius: 1px;
    }
    &:hover {
      color: var(--color-secondary);
      &::after {
        background-color: var(--color-secondary);
      }
    }
    &.active {
      color: var(--color-brand);
      &:after {
        background-color: var(--color-brand);
      }
      &:hover {
        color: var(--color-primary);
        &::after {
          background-color: var(--color-primary);
        }
      }
    }
  }
  &.active {
    color: var(--color-brand);
    &:after {
      background-color: var(--color-brand);
    }
    &:hover {
      color: var(--color-primary);
      &::after {
        background-color: var(--color-primary);
      }
    }
  }
}

span {
  &.brand {
    color: var(--color-brand);
  }
}
section {
  padding: var(--base-size-8);
  margin: var(--base-size-8);
  border: 1px solid var(--color-border);
}
svg,
img,
fieldset,
legend {
  pointer-events: none;
  flex-shrink: 0;
}
svg {
  fill: currentColor;
  transition: fill $timer / 6 cubic-bezier(0.4, 0, 0.2, 1) $timer / 2;
  user-select: none;
}
img {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  object-fit: cover;
  transition: all 0.3s;
  background-color: rgba(var(--color-primary), 0.8);
}
hr {
  height: 2px;
  margin: 5px 0;
  box-shadow: 0 0 2px var(--color-border);
}
small {
  &.green {
    color: var(--color-msg-success);
  }
  &.red {
    color: var(--color-msg-error);
  }
  &.blue {
    color: var(--color-msg-info);
  }
  &.gray {
    color: var(--color-secondary);
  }
}

*[disabled] {
  @extend %disabled;
}
