.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 960px;
  min-height: 95vh;
  padding: 32px;
  margin: 0 auto;
  @media screen and (max-width: 968px) {
    & {
      width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    & {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
