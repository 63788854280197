.loader {
  position: relative;
  top: 0.12em;
  display: inline-flex;
  color: currentColor;
  svg {
    width: 1em;
    height: 1em;
  }
}
