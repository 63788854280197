.box {
  min-width: calc(8rem + 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.number,
.disabled {
  border: 1px solid var(--color-disabled);
  // cursor: not-allowed !important;
  display: inline-flex;
  border-radius: var(--radius);
  input[type='number'] {
    max-width: 3rem;
    border: none;
    border-radius: var(--radius);
    padding: 8px 12px;
    font-weight: 500;
    text-align: center;
  }
}
.number {
  button {
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 0;
    opacity: 0;
    &:first-of-type {
      border-radius: var(--radius) 0 0 var(--radius);
    }
    &:last-of-type {
      border-radius: 0 var(--radius) var(--radius) 0;
    }
    &:hover {
      background-color: var(--color-active);
    }
    &::before,
    &::after {
      display: inline-block;
      position: absolute;
      content: '';
      width: 1rem;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%);
    }
    &:last-of-type:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  &:hover {
    button {
      width: 2.5rem;
      opacity: 100%;
    }
    input {
      border: solid var(--color-border);
      border-width: 0px 1px;
      border-radius: 0;
    }
  }
}
