.bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: var(--color-shadow);
  background-color: #2019207f;
}
.modal {
  position: absolute;
  box-sizing: border-box;
  z-index: 2;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px var(--color-shadow);
  width: 500px;
  padding: 16px;
  font-size: var(--font-size, 16px);
  color: var(--color-primary);
  background-color: var(--color-dialog);
  border-radius: var(--radius, 8px);
  border: 1px solid var(--color-border);
  .title {
    font-size: var(--font-size-h1, 24px);
    font-weight: 600;
    text-transform: capitalize;
  }
  @media screen and (max-width: 768px) {
    & {
      width: 100%;
    }
  }
}
