.card {
  box-sizing: border-box;
  width: 535px;
  background-color: var(--color-dialog);
  padding: 32px;
  margin: 0 auto;
  border-radius: calc(var(--radius) * 3);
  overflow: hidden;
  @media screen and (max-width: 576px) {
    & {
      box-sizing: border-box;
      width: 100%;
      padding: var(--padding);
      border-radius: var(--padding);
    }
  }
  & hr {
    margin: 16px 0;
  }
}

.wide {
  width: 100%;
}

.border {
  border: 1px solid var(--color-border);
  box-shadow: 0 0px 4px var(--color-border) !important;
}

.nopadding {
  padding: 0px;
}
