.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.show,
.hide {
  display: inline-flex;
  align-items: center;
  color: var(--color-brand);
  &:hover {
    color: inherit;
  }
}

.hide {
  & svg {
    rotate: 180deg;
  }
}

.password {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in;
  max-height: 200px;
  margin-top: var(--padding);
  opacity: 1;
  &_hidden {
    margin: 0px;
    max-height: 0px;
    opacity: 0;
  }
  & > button {
    margin-top: var(--padding);
  }
}

.arrow {
  display: inline-block;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transition: all 0.3s ease-in;
  transform: rotate(45deg);
  top: 10px;
  -webkit-transform: rotate(45deg);
  &_hidden {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
