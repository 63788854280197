.head {
  padding: 2rem 2rem 1rem;
  background-color: var(--color-head);
}

.switch {
  padding: 1rem 2rem;
  background-color: var(--color-canvas-default);

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: var(--color-input);
    border-radius: 20px;
  }
  & button {
    box-sizing: border-box;
    padding: var(--padding);
    width: 50%;
    border-radius: 20px;
    &.active {
      background-color: var(--color-brand);
    }
  }
}

.item {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  & > button {
    margin-top: var(--padding);
  }
  &:last-of-type {
    margin-bottom: 2rem;
  }
}

.metamask {
  transition: opacity 0.2s ease-in;
  display: inline-flex;
  align-items: center;
  width: 1em;
  height: 1em;
  &:hover {
    opacity: 0.7;
  }
}
