.indicator {
  & > h1 {
    margin: calc(var(--padding) * 2) var(--padding) var(--padding);
  }
  & > div {
    border: 1px solid var(--color-border);
    border-radius: var(--padding);
    background-color: var(--color-dialog);
    padding: 16px;
    & h1 {
      text-align: center;
    }
    @media screen and (max-width: 480px) {
      & {
        padding: 8px;
      }
      & > div:nth-child(2) {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      & > div:nth-child(3) {
        display: none;
      }
    }
  }
}
